th::before {
    content: '↓';
    opacity: 0;
}

.th--down::before {
    content: '↓';
    opacity: 1;
}
.th--up::before {
    content: '↑';
    opacity: 1;
}

td:hover > div.alias {
    position: relative;
    cursor: pointer;
    width: 100%;
}


td:hover > div.alias::after {
    content: attr(data-alias);
    position: absolute;
    font-size: 14px;
    font-weight: normal;
    top: -10px;
    width: 200px;
    left: 86px;
}
