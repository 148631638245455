@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Barlow:wght@100;400;500&display=swap');

html {
    font-size: 14px;
    background-color: rgb(232, 239, 255);
    font-family: 'Barlow', sans-serif;
}

@media (max-width: 639px) {
    .xs\:hidden {
        display: none;
    }
}
